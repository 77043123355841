.flex-1 {
    flex: 1;
}

.ReactTable .rt-thead.-filters .rt-th {
    overflow: visible;
}

.user-device-list .custom-checkbox {
    float: left;
    width: 33%;
}

.user-device-list {
    clear: both;
    overflow: hidden;
}

body .input-range {
    margin-top: 14px;
    margin-left: 6px;
    width: calc(100% - 12px);
}

.dashboard-sensor {
    position: relative;
}
.pull-right {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
}

.notify .heartbit {
    z-index: 1;
}

@keyframes heartbit
{
    0% {
    -webkit-transform: scale(0.8);
    }
    5% {
    -webkit-transform: scale(0.9);
    }
    10% {
    -webkit-transform: scale(0.8);
    }
    15% {
    -webkit-transform: scale(1);
    }
    50% {
    -webkit-transform: scale(0.8);
    }
    100% {
    -webkit-transform: scale(0.8);
    }
}
